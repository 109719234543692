









import Vue from 'vue';

export default Vue.extend({
  name: 'ValuePair',
  props: {
    description: {
      type: String,
      required: true,
    },
    classLabel: {
      type: String,
      default: 'col-4 font-weight-bold',
    },
    classValue: {
      type: String,
      default: 'col-8',
    },
  },
});
