






import { Component, Vue, Prop } from 'vue-property-decorator';
import { Trade } from '@/types';

@Component({})
export default class ProfitSymbol extends Vue {
  @Prop({ required: true }) trade!: Trade;

  get isProfitable() {
    const res = (this.trade.profit_ratio ?? 0) > 0 || (this.trade.profit_abs ?? 0) > 0;
    return res;
  }
}
