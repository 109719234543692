import { render, staticRenderFns } from "./PairSummary.vue?vue&type=template&id=3531cc24&scoped=true&"
import script from "./PairSummary.vue?vue&type=script&lang=ts&"
export * from "./PairSummary.vue?vue&type=script&lang=ts&"
import style0 from "./PairSummary.vue?vue&type=style&index=0&id=3531cc24&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3531cc24",
  null
  
)

export default component.exports